import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import store from './store/index'
import './api/mock'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import qs from 'qs'
Vue.prototype.$qs = qs

// import '../static/ueditor/ueditor.config.js'
// import '../static/ueditor/ueditor.all.min.js'
// import '../static/ueditor/lang/zh-cn/zh-cn.js'
// import '../static/ueditor/ueditor.parse.min.js'

import { aesEncrypt,aesDecrypt } from "./utils/key"
Vue.prototype.$aesEncrypt = aesEncrypt;
Vue.prototype.$aesDecrypt = aesDecrypt;

import mixins from './mixins/mixins.js'
Vue.mixin(mixins)
Vue.filter('filterPrice', function(value) {
  if (!value) return ''
  value = value.toString()
  return '￡' + parseFloat(value).toFixed(2)
})


Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // store.commit('addMenu', router)
  }
}).$mount('#app')
