import {
	userstatus,
	membersource,
	memberstatus,
	isOrNotall,
	isornot,
	logtype,
	companyall,
	companystatus,
	flowtype,
	creditsflowtype,
	packagestatus,
	packagesetall,
	drawalstatus,
	initselectfunctemplate
	} from '../api/index'
export default {
  data() {
    return {
      pageSize:10
    }
  },
  methods: {
	routeTo(path){
	  	this.$router.push(path, ()=>{}, ()=>{})
	},
	// routeTo(path){
	// 	this.$router.push(path)
	// },  
    locasetItem(name, data) {
      window.sessionStorage.setItem(name, data)
    },
    locagetItem(name) {
      window.sessionStorage.getItem(name)
    },
	validateForm(refname){
	    let flag = null
	    this.$refs[refname].validate(valid => {
			if (valid) {
			  flag = true
			} else {
			  flag = false
			}
	    })
	    return flag
	},
	//用户状态
	async userstatus(){
		try {
			const {data} = await userstatus()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//终端用户注册来源状态
	async membersource(){
		try {
			const {data} = await membersource()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//终端用户状态
	async memberstatus(){
		try {
			const {data} = await memberstatus()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//是否(含全部)
	async isOrNotall(){
		try {
			const {data} = await isOrNotall()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//是否(不含全部)
	async isornot(){
		try {
			const {data} = await isornot()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//日志类型
	async logtype(){
		try {
			const {data} = await logtype()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//所有企业
	async companyall(){
		try {
			const {data} = await companyall()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//企业状态
	async companystatus(){
		try {
			const {data} = await companystatus()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//流水类型
	async flowtype(){
		try {
			const {data} = await flowtype()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	
	//流水类型
	async creditsflowtype(){
		try {
			const {data} = await creditsflowtype()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//套餐状态
	async packagestatus(){
		try {
			const {data} = await packagestatus()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//全部套餐
	async packagesetall(){
		try {
			const {data} = await packagesetall()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//提现状态
	async drawalstatus(){
		try {
			const {data} = await drawalstatus()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
	//开启模块
	async initselectfunctemplate(){
		try {
			const {data} = await initselectfunctemplate()
			if (data.code == 200) {
				return data.data
			}else{
				this.$message.error(data.msg);
			}
		} catch (e) {
			//TODO handle the exception
		}
	},
  },
}