import axios from "axios";
import router from "@/router";

// 封装一个axios实例
const http = axios.create({
    baseURL: '/api',
    timeout: 10000
})
let flag=false
// 请求拦截器
http.interceptors.request.use(config => {
    config.data = JSON.stringify(config.data);
    config.headers = {
		'Accept-Language': localStorage.getItem('langValue') || 'zh',
		'Content-Type':'application/json'//   x-www-form-urlencoded
    }
	 const token = localStorage.getItem('tax_adminToken')
	if(token){

	  config.headers.Authorization = token
	  // config.headers.Authorization = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJNSU5HIiwiaWF0IjoxNzAzMTQ4MDkxLCJ5cV91c2VySWQiOjZ9.HoCLQWKlspdpCWrDOlAdJuRD7r79SKaZNvliSHClcas'
		// config.headers.Authorization = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJNSU5HIiwiaWF0IjoxNzA0NzY1NzI4LCJ6cnBfdXNlcklkIjoxfQ.I9X1LlKBarenGNAi6Y6QlRcbw0svGNmKwB1giimBs2Y'
	}

    return config;
}, error => {

    return Promise.reject(error);
})

// 响应拦截器
http.interceptors.response.use(response => {
  // console.log('router--------',router.currentRoute)
  if(response.data.code==401){
    localStorage.setItem('tax_adminToken','')
    if(flag) return
    flag=true
    if(router.currentRoute.path!='/login'){
      router.replace({
        path:'/login'
      })
      setTimeout(() => {
        flag=false
      },1000)
    }
  }
    return response;
}, error=> {
	if(error.response){
		switch(error.response.status){
			case 200:
				localStorage.setItem('tax_adminToken','')
				this.$router.push('/login')

			break;
		}
	}
    return Promise.reject(error);
})

// export default http

// get请求
export function getRequest(url, params) {
  return new Promise((resolve, reject) => {
    http({
      method: 'get',
      url,
      params,
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
// post请求
export function postRequest(url, data, params) {
  return new Promise((resolve, reject) => {
    http({
      method: 'post',
      url,
      data,
	  params
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}