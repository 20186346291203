import Vue from "vue";
import Vuex from 'vuex';
import {
	messagegetTotal,
	} from '../api/index'

Vue.use(Vuex)

// 创建Vuex实例并导出
export default new Vuex.Store({
	state:{
		unreadCount:0,
	},
	getters:{
		unreadCount:state => state.unreadCount
	},
	actions:{
		async messagegetTotal({state}){
			try {
				const {data} = await messagegetTotal()
				if (data.code == 200) {
					state.unreadCount = data.data.filter(item=>{ return item.status == 0})[0].number
					console.log('state.unreadCount:',state.unreadCount)
				}else{
					this.$message.error(data.msg);
				}
			} catch (e) {
				//TODO handle the exception
			}
		},

	}
})