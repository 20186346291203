import { getRequest,postRequest } from '../utils/request'

//---登录----
// export const login = (params) => getRequest('/pt-auth/login',params)//登录
export const login = (params) => getRequest('/zrp-auth/login',params)//登录
export const createcode = () => getRequest('/zrp-auth/create-code')//获取验证码
export const exitLogin = () => getRequest('/zrp-auth/exit')//退出登录
//---注册---
export const companyauthregister = (params) => postRequest('/company-auth/register',{},params)//注册
export const registercode = (params) => postRequest('/company-auth/register-code',{},params)//获取注册验证码


//---企业管理----
//企业列表
export const companylist = (params) => getRequest('/pt/company/company/index',params)//列表
export const companyall = (params) => getRequest('/pt/company/company/all',params)//所有企业
export const companydetail = (params) => getRequest('/pt/company/company/detail',params)//详情
export const companyadd = (params) => postRequest('/pt/company/company/add',{},params)//新增
export const companyactivation = (params) => postRequest('/pt/company/company/activation',{},params)//激活
export const companyfreeze = (params) => postRequest('/pt/company/company/freeze',{},params)//冻结
export const companythaw = (params) => postRequest('/pt/company/company/thaw',{},params)//解冻
export const companyaiset = (params) => postRequest('/pt/company/company/ai-set',{},params)//ai接入

//企业用户
export const companyuserlist = (params) => getRequest('/pt/company/user/index',params)//列表
export const companyuserdetail = (params) => getRequest('/pt/company/user/detail',params)//详情
export const companyuserlockaccount = (params) => getRequest('/pt/company/user/lock-account',params)//冻结解冻
export const companyuserloginlimit = (params) => getRequest('/pt/company/user/login-limit',params)//解除登录限制
export const companyuserrolelist = (params) => getRequest('/pt/company/user/roleList',params)//角色
export const companyusercountrycodelist = () => getRequest('/pt/company/user/countryCodeList')//区号
export const companyuseradd = (params) => postRequest('/pt/company/user/add',{},params)//新增
export const companyuseredit = (params) => postRequest('/pt/company/user/edit',{},params)//编辑
export const companyuserresetpassword = (params) => postRequest('/pt/company/user/resetPassword',{},params)//重置密码


//---企业试用期管理---
export const companytryindex = (params) => getRequest('/pt/company/try/index',params)//列表
export const companytrypackage = () => getRequest('/pt/company/try/package')//全部套餐（试用套餐）
export const companytrysettry = (params) => postRequest('/pt/company/try/setTry',{},params)//设置试用期
export const companytryDetail = (params) => postRequest('/pt/company/try/tryDetail',{},params)//企业试用信息



//---企业订阅管理---
//套餐设置
export const packagesetindex = (params) => getRequest('/pt/package/set/index',params)//列表
export const packagesetdetail = (params) => getRequest('/pt/package/set/detail',params)//详情
export const packagesetfunctemplate = (params) => getRequest('/pt/package/set/funcTemplate',params)//企业功能模板
export const packagesetadd = (params) => postRequest('/pt/package/set/add',{},params)//新增
export const packagesetcountprice = (params) => postRequest('/pt/package/set/countPrice',{},params)//计算最低费用
export const packagesetedit = (params) => postRequest('/pt/package/set/edit',{},params)//编辑
export const packagesetoffline = (params) => postRequest('/pt/package/set/offline',{},params)//下线
export const packagesetrelease = (params) => postRequest('/pt/package/set/release',{},params)//发布
export const packagesetdelete = (params) => postRequest('/pt/package/set/delete',{},params)//删除
//订阅记录
export const companysubscriptionpage = (params) => getRequest('/pt/company/company-subscription/page',params)//列表

//流水查询
export const companycreditstransaction = (params) => getRequest('/pt/company/company-credits-transaction/page',params)//credits流水列表
export const companysmstransaction = (params) => getRequest('/pt/company/company-sms-transaction/page',params)//预警短信流水列表
export const companyemailtransaction = (params) => getRequest('/pt/company/company-email-transaction/page',params)//预警邮件流水列表

//---企业提现管理---
//当前
export const companywithdrawalindex = (params) => getRequest('/pt/company/withdrawal/index',params)//列表
export const companywithdrawalcomplete = (params) => getRequest('/pt/company/withdrawal/complete',params)//完成
export const companywithdrawaldetail = (params) => getRequest('/pt/company/withdrawal/detail',params)//详情
export const companywithdrawalpass = (params) => getRequest('/pt/company/withdrawal/pass',params)//通过
export const companywithdrawalreject = (params) => getRequest('/pt/company/withdrawal/reject',params)//驳回
//历史
export const companywithdrawalhistory = (params) => getRequest('/pt/company/withdrawal/history',params)//列表

//---企业数据---
//企业终端用户列表
export const memberpage = (params) => getRequest('/pt/member/member/page',params)//列表
export const membercreditspage = (params) => getRequest('/pt/member/member/credits-page',params)//终端用户credits记录
export const memberrecordpage = (params) => getRequest('/pt/member/member/record-page',params)//会话记录
export const memberscribepage = (params) => getRequest('/pt/member/member/scribe-page',params)//终端用户订阅记录
//企业终端用户会话记录
export const chatconversationqymemberpage = (params) => getRequest('/pt/member/chat-conversation/qy-member-page',params)//列表
export const companychatmessagelist = (params) => getRequest('/company/chat-message/list',params)//消息列表
export const memberchatconversationqymembermessage = (params) => getRequest('/pt/member/chat-conversation/qy-member-message',params)//消息记录
export const setReadAll = () => postRequest('/pt/member/chat-conversation/qy-member-set-history') // 一键将所有会话置为历史会话

//企业套餐列表
export const companypackagelist = (params) => getRequest('/pt/company/company-package/list',params)//列表
export const companypackagefind = (params) => getRequest('/pt/company/company-package/find',params)//详情
export const companypackagegetStatus = (params) => getRequest('/pt/company/company-package/getStatus',params)//套餐状态
//企业终端用户订阅记录
export const membersubscriptionlist = (params) => getRequest('/pt/company/member-subscription/list',params)//列表
export const membersubscriptiongetValid = () => getRequest('/pt/company/member-subscription/getValid')//是否有效

//企业数据统计
export const membercountaddCount = (params) => getRequest('/pt/company/member-count/addCount',params)//新增用户
export const membercounttotalCount = (params) => getRequest('/pt/company/member-count/totalCount',params)//累计用户
export const onlineaveragecount = (params) => getRequest('/pt/company/member-online-time-count/average-count',params)//平均在线时长
export const onlineranking = (params) => getRequest('/pt/company/member-online-time-count/ranking',params)//企业客户在线时长
export const questioncountaverageCount = (params) => getRequest('/pt/company/question-count/averageCount',params)//平均问题个数
export const questioncountranking = (params) => getRequest('/pt/company/question-count/ranking',params)//问题最多客户
export const membersubscriptioncountaddCount = (params) => getRequest('/pt/company/member-subscription-count/addCount',params)//新增订阅统计
export const membersubscriptioncountamountCount = (params) => getRequest('/pt/company/member-subscription-count/amountCount',params)//新增订阅金额
export const membersubscriptioncounttotalCount = (params) => getRequest('/pt/company/member-subscription-count/totalCount',params)//累计订阅统计
export const membersubscriptioncounttotalAmount = (params) => getRequest('/pt/company/member-subscription-count/totalAmount',params)//累计订阅金额
export const keywordcountranking = (params) => getRequest('/pt/company/keyword-count/ranking',params)//关键词统计
export const ageAgeRange = (params) => getRequest('/pt/company/member-count/ageAgeRange',params)//年龄分布

//平台数据统计
export const dayCompanyNum = (params) => getRequest('/pt/company/pt-data-count/dayCompanyNum',params)//当日新增企业数
export const dayCompanyUserNum = (params) => getRequest('/pt/company/pt-data-count/dayCompanyUserNum',params)//当日新增企业员工数
export const dayMemberNum = (params) => getRequest('/pt/company/pt-data-count/dayMemberNum',params)//当日新增终端用户数
export const dayChatNum = (params) => getRequest('/pt/company/pt-data-count/dayChatNum',params)//当日会话数
export const dayCreditsNum = (params) => getRequest('/pt/company/pt-data-count/dayCreditsNum',params)//当日credits数
export const dayEmailNum = (params) => getRequest('/pt/company/pt-data-count/dayEmailNum',params)//当日预警邮件数
export const daySmsNum = (params) => getRequest('/pt/company/pt-data-count/daySmsNum',params)//当日预警短信数

export const dayTotalCompanyNum = (params) => getRequest('/pt/company/pt-data-count/dayTotalCompanyNum',params)//截至当日新增企业数
export const dayTotalCompanyUserNum = (params) => getRequest('/pt/company/pt-data-count/dayTotalCompanyUserNum',params)//截至当日新增企业员工数
export const dayTotalMemberNum = (params) => getRequest('/pt/company/pt-data-count/dayTotalMemberNum',params)//截至当日新增终端用户数
export const dayTotalChatNum = (params) => getRequest('/pt/company/pt-data-count/dayTotalChatNum',params)//截至当日会话数
export const dayTotalCreditsNum = (params) => getRequest('/pt/company/pt-data-count/dayTotalCreditsNum',params)//截至当日credits数
export const dayTotalEmailNum = (params) => getRequest('/pt/company/pt-data-count/dayTotalEmailNum',params)//截至当日预警邮件数
export const dayTotalSmsNum = (params) => getRequest('/pt/company/pt-data-count/dayTotalSmsNum',params)//截至当日预警短信数

export const companyCount = (params) => getRequest('/pt/company/pt-data-count/companyCount',params)//当日新增企业数曲线
export const companyUserCount = (params) => getRequest('/pt/company/pt-data-count/companyUserCount',params)//当日新增企业员工数曲线
export const memberCount = (params) => getRequest('/pt/company/pt-data-count/memberCount',params)//当日新增终端用户数曲线
export const chatCount = (params) => getRequest('/pt/company/pt-data-count/chatCount',params)//当日会话数曲线
export const creditsCount = (params) => getRequest('/pt/company/pt-data-count/creditsCount',params)//当日credits数曲线
export const emailCount = (params) => getRequest('/pt/company/pt-data-count/emailCount',params)//当日预警邮件数曲线
export const smsCount = (params) => getRequest('/pt/company/pt-data-count/smsCount',params)//当日预警短信数曲线

export const totalCompanyCount = (params) => getRequest('/pt/company/pt-data-count/totalCompanyCount',params)//截至当日新增企业数曲线
export const totalCompanyUserCount = (params) => getRequest('/pt/company/pt-data-count/totalCompanyUserCount',params)//截至当日新增企业员工数曲线
export const totalMemberCount = (params) => getRequest('/pt/company/pt-data-count/totalMemberCount',params)//截至当日新增终端用户数曲线
export const totalChatCount = (params) => getRequest('/pt/company/pt-data-count/totalChatCount',params)//截至当日会话数曲线
export const totalCreditsCount = (params) => getRequest('/pt/company/pt-data-count/totalCreditsCount',params)//截至当日credits数曲线
export const totalEmailCount = (params) => getRequest('/pt/company/pt-data-count/totalEmailCount',params)//截至当日预警邮件数曲线
export const totalSmsCount = (params) => getRequest('/pt/company/pt-data-count/totalSmsCount',params)//截至当日预警短信数曲线


//---系统管理---
//系统角色管理
export const systemzrprolepage = (params) => getRequest('/pt/system/zrp-role/page',params)//列表
export const systemzrprolerolelist = () => getRequest('/pt/system/zrp-role/role-list')//角色下拉框
export const systemzrproledetail = (params) => getRequest('/pt/system/zrp-role/detail',params)//详情
export const systemzrproledelete = (params) => getRequest('/pt/system/zrp-role/delete',params)//删除
export const systemzrproleadd = (params) => postRequest('/pt/system/zrp-role/add',{},params)//新增
export const systemzrproleedit = (params) => postRequest('/pt/system/zrp-role/edit',{},params)//编辑
export const systemzrprolemenuadd = (params) => postRequest('/pt/system/zrp-role-menu/add',{},params)//授权
export const systemzrprolemenudetail = (params) => getRequest('/pt/system/zrp-role-menu/detail',params)//角色授权回显
export const systemzrproleroleusreadd = (params) => getRequest('/pt/system/zrp-role/role-usre-add',params)//用户-提交

//系统用户管理
export const systemzrpuserpage = (params) => getRequest('/pt/system/zrp-user/page',params)//列表
export const systemzrpuserdetail = (params) => getRequest('/pt/system/zrp-user/detail',params)//详情
export const systemzrpuserdelete = (params) => getRequest('/pt/system/zrp-user/delete',params)//删除
export const systemzrpuserlockaccount = (params) => getRequest('/pt/system/zrp-user/lock-account',params)//冻结/解冻
export const systemzrpuserloginstatus = (params) => getRequest('/pt/system/zrp-user/login-status',params)//解除登录限制
export const systemzrpuserusrelist = (params) => getRequest('/pt/system/zrp-user/usre-list',params)//获取不是该角色下的用户
export const systemzrpuserroleusrelist = (params) => getRequest('/pt/system/zrp-user/role-usre-list',params)//获取该角色下所有用户
export const systemzrpuseradd = (params) => postRequest('/pt/system/zrp-user/add',{},params)//新增
export const systemzrpuseredit = (params) => postRequest('/pt/system/zrp-user/edit',{},params)//编辑
export const systemzrpuserrestpassword = (params) => postRequest('/pt/system/zrp-user/rest-password',{},params)//重置密码
//系统菜单管理
export const systemzrpmenutree = () => getRequest('/pt/system/zrp-menu/tree')//菜单树列表
export const systemzrpmenudelete = (params) => getRequest('/pt/system/zrp-menu/delete',params)//删除
export const systemzrpmenuadd = (params) => postRequest('/pt/system/zrp-menu/add',{},params)//新增
export const systemzrpmenuedit = (params) => postRequest('/pt/system/zrp-menu/edit',{},params)//编辑
export const systemzrpmenuchild = (params) => getRequest('/pt/system/zrp-menu/child',params)//子级
export const getLang = () => getRequest('/member/member-lang/getLang') // 语言
//企业端菜单管理
export const companymenutree = () => getRequest('/pt/system/company/company-menu/tree')//菜单树列表
export const companymenudelete = (params) => getRequest('/pt/system/company/company-menu/delete',params)//删除
export const systemcompanymenudetail = (params) => getRequest('/pt/system/company/company-menu/detail',params)//列表
export const companymenuadd = (params) => postRequest('/pt/system/company/company-menu/add',params)//新增
export const companymenuedit = (params) => postRequest('/pt/system/company/company-menu/edit',params)//编辑
export const companymenuchild = (params) => getRequest('/pt/system/company/company-menu/child',params)//子级


//企业功能模板
export const systemcompanyfunctemplatepage = (params) => getRequest('/pt/system/company/func-template/page',params)//列表
export const systemcompanyfunctemplatedetai = (params) => getRequest('/pt/system/company/func-template/detail',params)//详情
export const systemcompanyfunctemplatedelete = (params) => getRequest('/pt/system/company/func-template/delete',params)//删除
export const systemcompanyfunctemplateadd = (params) => postRequest('/pt/system/company/func-template/add',{},params)//新增
export const systemcompanyfuntemplateedit = (params) => postRequest('/pt/system/company/func-template/edit',{},params)//编辑
//系统设置
export const systempriceconfigaddOrEdit = (data) => postRequest('/pt/system/price-config/addOrEdit',data)//新增/编辑
export const systempriceconfigdetail = () => postRequest('/pt/system/price-config/detail')//详情
//系统操作日志
export const systemzrpoperatlogpage = (params) => getRequest('/pt/system/zrp-operat-log/page',params)//列表
//企业端操作日志
export const systemcompanyoperatlog = (params) => getRequest('/pt/system/company-operat-log/page',params)//列表
// 终端用户操作日志
export const enduseroperationlog = params => getRequest('/pt/company/member-log/page',params) // 列表
//企业端语言配置
export const systemlanglist = (params) => getRequest('/pt/system/system/lang/list',params)//列表
export const systemlangfind = (params) => getRequest('/pt/system/system/lang/find/',params)//查询单条数据{id}
export const systemlangdelete = (params) => getRequest('/pt/system/system/lang/delete',params)//删除
export const systemlangcreate = (params) => postRequest('/pt/system/system/lang/create',params)//新增
export const systemlangedit = (params) => postRequest('/pt/system/system/lang/edit',{},params)//编辑
export const systemlangsetSort = (params) => postRequest('/pt/system/system/lang/setSort',{},params)//设置排序
//语言标签
export const systemlangTaglist = (params) => getRequest('/pt/system/langTag/list',params)//列表
export const systemlangTagfind = (params) => getRequest('/pt/system/langTag/find/',params)//查询单条数据{id}
export const systemlangTagdelete = (params) => getRequest('/pt/system/langTag/delete',params)//删除
export const systemlangTagcreate = (params) => postRequest('/pt/system/langTag/create',params)//新增
export const systemlangTagedit = (params) => postRequest('/pt/system/langTag/edit',params)//编辑
export const updateLangCache = () => getRequest('/pt/system/langTag/updateLangCache')//更新语言缓存
//企业端默认权限设置
export const companydefaultpermadd = (params) => postRequest('/pt/system/company/company-default-perm/add',{},params)//管理后台-企业角色默认全新配置
export const companydefaultpermdetail = () => getRequest('/pt/system/company/company-default-perm/detail')//默认权限回显



//---首页---
export const indexcountdata = () => getRequest('/pt/system/index/count-data')//统计数据
export const companytodolist = () => getRequest('/pt/system/company-todo/list')//待办列表
export const homegetTodo = () => getRequest('/pt/home/getTodo')//首页待办

//---角色权限---
export const systemzrprolemenupermtree = (params) => getRequest('/pt/system/zrp-role-menu/perm-tree',params)


//---消息---
export const messagelist = (params) => getRequest('/pt/message/list',params)//消息列表
export const messagefind = (params) => getRequest('/pt/message/find/',params)//详情
export const messagegetType = () => getRequest('/pt/message/getType')//类型选项
export const messagelatest = (params) => getRequest('/pt/message/latest',params)//最新消息（前n条）
export const messagesetRead = (params) => postRequest('/pt/message/setRead/'+params)//设置已读
export const messagesetReadBatch = (params) => postRequest('/pt/message/setReadBatch',{},params)//设置已读（批量）
export const messagegetTotal = () => postRequest('/pt/message/getTotal')//获取统计(已读未读数量)


//----下拉-----
export const userstatus = () => getRequest('/init-select/user-status')//用户状态
export const membersource = () => getRequest('/init-select/member-source')//终端用户注册来源状态
export const memberstatus = () => getRequest('/init-select/member-status')//终端用户状态
export const isOrNotall = () => getRequest('/init-select/isOrNot-all')//是否下拉选择 含全部:90
export const isornot = () => getRequest('/init-select/is-or-not')//是否下拉选择 不含全部:90
export const logtype = () => getRequest('/init-select/log-type')//系统日志操作类型
export const companystatus = () => getRequest('/init-select/company-status')//企业状态
export const flowtype = () => getRequest('/init-select/flow-type')//流水类型
export const creditsflowtype = () => getRequest('/init-select/credits-flow-type')//credits流水类型
export const packagestatus = () => getRequest('/init-select/package-status')//套餐状态
export const packagesetall = () => getRequest('/pt/package/set/all')//全部套餐
// export const drawalstatus = () => getRequest('/init-select/drawal-status')//提现状态
export const drawalstatus = () => getRequest('/init-select/manage-drawal-status')//管理后台下拉提现状态

export const initselectfunctemplate = () => getRequest('/init-select/func-template')//开启模块

// 多语言翻译包
export const getLangMap = (data) => getRequest('/member/member-lang/getLangMap', data)

