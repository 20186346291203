import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter)

const routes = [
	{
	    path: '/loginmodulemain',
	    name: 'Loginmodulemain',
	    component: () => import('../Views/Login/LoginModuleMain.vue'),
		redirect:'/login',
		children:[
			{
				path: '/login',
				name: 'Login',
				component: () => import('../Views/Login/Login.vue'),
			},
			{
				path: '/register',
				name: 'Register',
				component: () => import('../Views/Login/Register.vue'),
			},
			{
				path: '/forgotpassword',
				name: 'Forgotpassword',
				component: () => import('../Views/Login/ForgotPassword.vue'),
			},
			{
				path: '/resetpassword',
				name: 'Resetpassword',
				component: () => import('../Views/Login/ResetPassword.vue'),
			},

		]
	},
	{
	    path: '/registersuccess',
	    name: 'Registersuccess',
	    component: () => import('../Views/Login/RegisterSuccess.vue'),
	},
    {
        path: '/',
        name:'Main',
        component: () => import('../Views/Main'),
        redirect: '/home',
        children: [
			{
				path: '/home',
				name: 'Home',
				component: () => import('../Views/Home/Home.vue'),// 首页
			},
			{
				path: '/message',
				name: 'Message',
				component: () => import('../Views/Message/Message.vue'),// 消息列表
			},
			// {
			// 	path: '/enterprisemanagement',
			// 	name: 'Enterprisemanagement',
			// 	component: () => import('../Views/EnterpriseManagement/Main.vue'),//企业管理
			// 	children: [
			// 		{
			// 			path: '/enterprisemanagement/enterpriselist/:title?',
			// 			name: 'EnterpriseList',
			// 			component: () => import('../Views/EnterpriseManagement/EnterpriseList.vue'),// 企业管理-企业列表
			// 		},
			// 		{
			// 			path: '/enterprisemanagement/enterpriseuser',
			// 			name: 'EnterpriseUser',
			// 			component: () => import('../Views/EnterpriseManagement/EnterpriseUser.vue'),// 企业管理-企业用户
			// 		},
			// 	]
			// },
			{
				path: '/enterprisemanagement/enterpriselist/:title?',
				name: 'EnterpriseList',
				component: () => import('../Views/EnterpriseManagement/EnterpriseList.vue'),// 企业管理-企业列表
			},
			{
				path: '/enterprisemanagement/enterpriseuser',
				name: 'EnterpriseUser',
				component: () => import('../Views/EnterpriseManagement/EnterpriseUser.vue'),// 企业管理-企业用户
			},
			{
				path: '/probation',
				name: 'Probation',
				component: () => import('../Views/Probation/Probation.vue'),// 试用期管理
			},
			{
				path: '/package/packgesettings',
				name: 'Packgesettings',
				component: () => import('../Views/Subscribe/PackageSettings.vue'),// 套餐设置
			},
			{
				path: '/package/subscrirecord',
				name: 'Subscriberecord',
				component: () => import('../Views/Subscribe/SubscribeRecord.vue'),// 订阅记录
			},
			{
				path: '/package/turnover',
				name: 'Turnover',
				component: () => import('../Views/Subscribe/Turnover.vue'),// 流水查询
			},
			{
				path: '/withdrawal/currentapplication/:title?',
				name: 'Currentapplication',
				component: () => import('../Views/Withdrawal/CurrentApplication.vue'),// 当前提现申请
			},
			{
				path: '/withdrawal/historicalapplications',
				name: 'Historicalapplications',
				component: () => import('../Views/Withdrawal/HistoricalApplications.vue'),// 历史提现申请
			},
			{
				path: '/enduserlistmain',
				name: 'Enduserlistmain',
				component: () => import('../Views/DataAnalysis/EndUserList/EndUserListMain.vue'),
				children: [
					{
						path: '/enduserlistmain/enduserlist',
						name: 'Enduserlist',
						component: () => import('../Views/DataAnalysis/EndUserList/EndUserList.vue'),// 企业终端用户列表
					},
					{
						path: '/enduserlistmain/usersessionrecord/:id',
						name: 'Usersessionrecord',
						component: () => import('../Views/DataAnalysis/EndUserList/UserSessionRecord.vue'),// 用户会话记录
					},
					{
						path: '/enduserlistmain/usersubscriberecord/:id',
						name: 'Usersubscriberecord',
						component: () => import('../Views/DataAnalysis/EndUserList/UserSubscribeRecord.vue'),// 用户订阅记录
					},
					{
						path: '/enduserlistmain/usercreditsrecord/:id',
						name: 'Usercreditsrecord',
						component: () => import('../Views/DataAnalysis/EndUserList/UserCreditsRecord.vue'),// 用户credits记录
					}
				]
			},
			{
				path: '/enduserlistmain/endusersessionlist',
				name: 'Endusersessionlist',
				component: () => import('../Views/DataAnalysis/EnduserSessionList.vue'),// 企业终端用户会话记录
			},
			{
				path: '/enduserlistmain/packagelist',
				name: 'Packagelist',
				component: () => import('../Views/DataAnalysis/PackageList.vue'),// 企业套餐列表
			},
			{
				path: '/enduserlistmain/endusersubscriberecords',
				name: 'Endusersubscriberecords',
				component: () => import('../Views/DataAnalysis/EnduserSubscribeRecords.vue'),// 企业终端用户订阅记录
			},
			{
				path: '/enduserlistmain/enterprisedatastatistics',
				name: 'Enterprisedatastatistics',
				component: () => import('../Views/DataAnalysis/EnterpriseDataStatistics.vue'),// 企业数据统计
			},
			{
				path: '/enduserlistmain/platformdatastatistics',
				name: 'Platformdatastatistics',
				component: () => import('../Views/DataAnalysis/PlatformDataStatistics.vue'),// 平台数据统计
			},
			{
				path: '/system/rolemanagement',
				name: 'Rolemanagement',
				component: () => import('../Views/System/RoleManagement.vue'),// 系统角色管理
			},
			{
				path: '/system/usermanagement',
				name: 'Usermanagement',
				component: () => import('../Views/System/UserManagement.vue'),// 系统用户管理
			},
			{
				path: '/system/smenumanagement',
				name: 'Smenumanagement',
				component: () => import('../Views/System/SMenuManagement.vue'),// 系统菜单管理
			},
			{
				path: '/system/emenumanagement',
				name: 'Emenumanagement',
				component: () => import('../Views/System/EMenuManagement.vue'),// 企业端菜单管理
			},
			{
				path: '/system/enterprisefuntemplate',
				name: 'Enterprisefuntemplate',
				component: () => import('../Views/System/EnterpriseFunTemplate.vue'),//企业功能模板
			},
			{
				path: '/system/systemsetting',
				name: 'Systemsetting',
				component: () => import('../Views/System/SystemSetting.vue'),// 系统设置
			},
			{
				path: '/system/soperationlog',
				name: 'Soperationlog',
				component: () => import('../Views/System/SOperationLog.vue'),// 系统操作日志
			},
			{
				path: '/system/eoperationlog',
				name: 'Eoperationlog',
				component: () => import('../Views/System/EOperationLog.vue'),// 企业端操作日志
			},
			{
				path: '/system/enterpriselanguageconfig',
				name: 'Enterpriselanguageconfig',
				component: () => import('../Views/System/EnterpriseLanguageConfig.vue'),//企业端语言配置
			},
			{
				path: '/system/enterprisedefaultpermissconfig',
				name: 'Enterprisedefaultpermissconfig',
				component: () => import('../Views/System/EnterpriseDefaultPermissConfig.vue'),//企业端默认权限配置
			},
			{
				path: '/system/enduseroperationlog',
				name: 'EnduserOperationLog',
				component: () => import('../Views/System/EnduserOperationLog.vue') // 终端用户操作日志
			}
        ]
    },

]

const router = new VueRouter({
    routes
})


router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('tax_adminToken')

    /* if (!token && to.name !== 'Login' && to.name !== 'Register') {
        next({ name: 'Login' })
    } else  */if (token && to.name === 'Login') {
        next({ name: 'Home' })
    } else if(to.name!=='Register'){
			localStorage.setItem('langValue','zh')
			next()
		} else if(to.name==='Register'){
			localStorage.setItem('langValue','en')
			next()
		} else {
        next()
    }
})


export default router