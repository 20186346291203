// 饼图
const videoData = [
    {
        name: '小米',
        value: 2999
    },
    {
        name: '苹果',
        value: 5999
    },
    {
        name: 'vivo',
        value: 1500
    },
    {
        name: 'oppo',
        value: 1999
    },
    {
        name: '魅族',
        value: 2200
    },
    {
        name: '三星',
        value: 4500
    }
]

export default videoData